import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getMainMenu } from 'src/selectors/settings'
import { StyledChildrenWrapper, StyledItemWrapper, StyledMainMenutem } from './MainMenu.style'
import { useState } from 'react'
import { TiPlus } from 'react-icons/ti'
const MainMenu: React.FC = (props) => {
    const [showChildren, setShowChildren] = useState<number>(-1)
    const mainMenu = useSelector(getMainMenu)
    return (
        <FlexWrapper as={Padder} left="xs">
            {mainMenu?.items.map(({ ID, title, url, children = [] }, index) => (
                <StyledItemWrapper
                    key={ID}
                    onMouseEnter={() => {
                        setShowChildren(ID as number)
                    }}
                    onMouseLeave={() => {
                        setShowChildren(-1)
                    }}
                >
                    <StyledMainMenutem
                        as={Text}
                        textVariant="menu"
                        css={{ fontWeight: 'bold' }}
                        withLine
                        className={index === 0 ? 'notranslate' : ''}
                    >
                        {children.length > 0 ? (
                            <FlexWrapper as="span" align="start" css={{ gap: '$xxs' }}>
                                {title} <TiPlus />
                            </FlexWrapper>
                        ) : (
                            <Link to={url}>{title}</Link>
                        )}
                    </StyledMainMenutem>
                    {showChildren === ID && (
                        <StyledChildrenWrapper>
                            <Padder top="xxs">
                                {children.map(({ ID, title, url }) => (
                                    <Text
                                        as={Link}
                                        to={url}
                                        textVariant="small"
                                        css={{
                                            display: 'block',
                                            '@hover': {
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            },
                                        }}
                                    >
                                        {title}
                                    </Text>
                                ))}
                            </Padder>
                        </StyledChildrenWrapper>
                    )}
                </StyledItemWrapper>
            ))}
        </FlexWrapper>
    )
}

export default MainMenu
