import { CmsLink } from 'src/types'
import { StyledInfos, StyledPageTitle } from './PageTitleWithDates.style'
import Container from 'src/components/_layout/Container/Container'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'
import arrow from 'src/assets/img/arrow.svg'
type PageTitleProps = {
    title: string
    from: string
    to: string
    city: string
    province: string
    link: CmsLink
}

const PageTitleWithDates: React.FC<PageTitleProps> = ({ title, from, to, city, province, link }) => {
    return (
        <StyledPageTitle>
            <Container>
                <Padder top={{ '@initial': 'sm', '@md': 'lg', '@lg': 'xl' }}>
                    <Text as="h1" dangerouslySetInnerHTML={{ __html: title }} textVariant="heading1" />
                    <FlexWrapper
                        justify={{ '@md': 'between' }}
                        direction={{ '@mdDown': 'column' }}
                        css={{ '@mdDown': { gap: '$sm' } }}
                    >
                        <StyledInfos as={Padder} top="xxs">
                            <FlexWrapper align="center" css={{ gap: '$xxs' }}>
                                <Text textVariant="titleSmall" className="a-text">
                                    {from}
                                </Text>
                                <Image url={arrow} />
                                <Text textVariant="titleSmall" className="a-text">
                                    {to}
                                </Text>
                            </FlexWrapper>
                            <FlexWrapper align="center" css={{ gap: '$xxs' }}>
                                <Text textVariant="titleSmall" className="a-text">
                                    {city}
                                </Text>
                                <Image url={arrow} className="rotated-arrow" />
                                <Text textVariant="titleSmall" className="a-text">
                                    {province}
                                </Text>
                            </FlexWrapper>
                        </StyledInfos>
                        <Link to={link.url}>
                            <Button variant="big">{link.title}</Button>
                        </Link>
                    </FlexWrapper>
                </Padder>
            </Container>
        </StyledPageTitle>
    )
}

export default PageTitleWithDates
