import { PostPreview } from 'src/types'
import * as Styles from './PostPreviewWithImage.style'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import { Link, useHistory } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'

const PostPreviewWithImage: React.FC<
    Required<PostPreview> & { forceReveal?: boolean; fullWidth?: boolean; disableLink?: boolean }
> = ({ title, excerpt, image, link, forceReveal, fullWidth, disableLink }) => {
    const { push } = useHistory()
    return (
        <Styles.Wrapper as={Padder} top="xs" css={{ cursor: disableLink ? 'default' : 'pointer' }}>
            <Styles.ImageWrapper
                onClick={
                    !disableLink
                        ? () => {
                              push(link.url)
                          }
                        : undefined
                }
            >
                <div>
                    {forceReveal ? (
                        <Image src={image.url} alt={image.alt} />
                    ) : (
                        <ImageReveal src={image.url} alt={image.alt} />
                    )}
                    <Revealer low forceReveal={forceReveal}>
                        <Styles.Title>
                            <Text textVariant="title">{title}</Text>
                        </Styles.Title>
                    </Revealer>
                </div>
            </Styles.ImageWrapper>
            <Revealer forceReveal={forceReveal}>
                <Padder top="xxs" css={!fullWidth ? { paddingLeft: '$xs', paddingRight: '$xs' } : undefined}>
                    <RichText htmlString={excerpt} textVariant="base2" />
                    {!disableLink && (
                        <Link to={link.url}>
                            <Text
                                textVariant="base2"
                                css={{
                                    fontWeight: '$bold',
                                    textDecoration: 'underline',
                                }}
                            >
                                {link.title}
                            </Text>
                        </Link>
                    )}
                </Padder>
            </Revealer>
        </Styles.Wrapper>
    )
}

export default PostPreviewWithImage
